import React, { Component } from "react";
import Popup from "reactjs-popup";
import { SlotService } from "../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

interface Props {
  gameCode: string;
  gameName: string;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  isOpen: boolean;
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  }

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  RenderGame = (info: any) => {
    return (
      <div
        className="Home_inner_wrapper__3m1qm"
        style={{ userSelect: "auto" }}
        onClick={() => {
          this.handleOpenSlot(info.code, info.gameCompany);
        }}
      >
        <div className="Home_company_btn__peP68" style={{ userSelect: "auto" }}>
          <div
            className="Home_btn_border__3nyfn Home_hot_tab__30Vog"
            style={{ userSelect: "auto" }}
          >
            {" "}
            <div
              className="Home_btn_panel__1bo1S"
              style={{ userSelect: "auto" }}
            >
              <div className="Home_tag__1KJp_" style={{ userSelect: "auto" }}>
                <img
                  src="/images/casino-tag-on.png"
                  style={{ userSelect: "auto" }}
                />
                <img
                  className="Home_on__2Aarn"
                  src="/images/casino-tag-on.png"
                  style={{ userSelect: "auto" }}
                />
              </div>
              {/* <img
                      className="Home_hot_icon__3m449"
                      src="images/hotel.png"
                      style={{ userSelect: "auto" }}
                    />{" "} */}
              <img
                className="Home_main_img__35857"
                src={`${info.imgUrl}`}
                style={{
                  userSelect: "auto",
                  width: "189px",
                  height: "164px",
                }}
              />
              <button
                className="Home_view_btn__14gRm"
                style={{ userSelect: "auto" }}
              >
                게임보기
              </button>
              <div
                className="Home_footer__1WdhD"
                style={{ userSelect: "auto" }}
              >
                {/* <img
                  src="/images/evolution.png"
                  style={{ userSelect: "auto" }}
                /> */}
                <span style={{ color: "#fff" }}>{info.nameEn}</span>
              </div>
            </div>
          </div>
          <p className="Home_name__3fbfx" style={{ userSelect: "auto" }}>
            <span style={{ userSelect: "auto" }}>{info.nameKo}</span>
            <span style={{ userSelect: "auto" }}>{info.nameKo}</span>
          </p>
        </div>
      </div>

      // <li
      //   style={{
      //     float: "left",
      //     paddingRight: "3px",
      //     paddingTop: "3px",
      //     padding: "10px",
      //   }}
      // >
      //   <a
      //     href="javascript:;"
      //     onClick={() => {
      //       this.handleOpenSlot(info.code, info.gameCompany);
      //     }}
      //     style={{ textAlign: "center" }}
      //   >
      //     <img
      //       src={`${info.imgUrl}`}
      //       data-src={`${info.imgUrl}`}
      //       className="img-responsive"
      //       style={{ width: "180px", height: "180px" }}
      //     />
      //     <p
      //       style={{
      //         overflow: "hidden",
      //         whiteSpace: "nowrap",
      //         textOverflow: "ellipsis",
      //         width: "140px",
      //         fontSize: "16px",
      //       }}
      //     >
      //       {info.nameKo}
      //     </p>
      //   </a>
      // </li>
    );
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="Modal_modal_wrapper__bmYcZ">
              <div className="Modal_modal_overlay__Ag4FI animate__animated animate__fadeIn"></div>
              <div
                className="Modal_modal_inner_wrapper__2J_Td"
                style={{ overflow: "scroll" }}
              >
                <div className="Modal_app_modal__WUCt9 animate__animated animate__bounceIn">
                  <div className="Modal_modal_header__3CHHh">
                    <img src="/images/logo.png" />
                    <button
                      className="Modal_close_modal__3PQ7Q"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="Mobile_modal_options__2LBvR"></div>
                  <div
                    className="Home_game_holder__IGxvx"
                    style={{ userSelect: "auto" }}
                  >
                    {this.state.games.map((row: any) => this.RenderGame(row))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
