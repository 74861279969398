import React, { Component } from "react";

interface Props {}
interface State {}

export class Footer extends Component<Props, State> {
  render() {
    return (
      <>
        <footer className="footer-section">
          <div className="copyright-container">
            <span>
              ⓒ COPYRIGHT SEVEN LUCK SLOT CASINO 2018 All RIGHTS RECEIVED.
            </span>
          </div>
          <div className="company-logo">
            <div className="container-main">
              <img src="/img/slot-logo/cq9.png" />
              <img src="/img/slot-logo/pragmatic.png" />
              <img src="/img/slot-logo/bng.png" />
              <img src="/img/slot-logo/evoplay.png" />
              <img src="/img/slot-logo/habanero.png" />
              <img src="/img/slot-logo/gameart.png" />
              <img src="/img/slot-logo/playstar.png" />
              <img src="/img/slot-logo/bbtech.png" />
              <img src="/img/slot-logo/dreamtech.png" />
              <img src="/img/slot-logo/playson.png" />
              <img src="/img/slot-logo/playngo.png" />
              <img src="/img/slot-logo/realtime.png" />
              <img src="/img/slot-logo/bgaming.png" />
              <img src="/img/slot-logo/ag.png" />
              <img src="/img/slot-logo/microgaming.png" />
              <img src="/img/slot-logo/aristocrat.png" />
              <img src="/img/slot-logo/betsoft.png" />
              <img src="/img/slot-logo/stargames.png" />
              <img src="/img/slot-logo/isoftbet.png" />
              <img src="/img/slot-logo/genesis.png" />
              <img src="/img/slot-logo/toptrend.png" />
              <img src="/img/slot-logo/tpg.png" />
              <img src="/img/slot-logo/novomatic.png" />
            </div>
          </div>
        </footer>
      </>
    );
  }
}
