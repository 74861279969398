import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: gameView.slot,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        console.log(s.slot);
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getFullYear() +
      "년 " +
      (yesterday.getMonth() + 1) +
      "월 " +
      yesterday.getDate() +
      "일";

    let tmp: any;
    tmp = [
      { id: "***ths", price: "86,000,000 원", date: yesterdayStr },
      { id: "***jvd", price: "83,000,000 원", date: yesterdayStr },
      { id: "**vhr", price: "82,000,000 원", date: yesterdayStr },
      { id: "***vdh", price: "80,000,000 원", date: yesterdayStr },
      { id: "***df", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  // handleGame = (name: string) => {
  //   this.slotService.get_slot_by_company(name).then((s: any) => {
  //     if (s.status === "success") {
  //       this.setState({ games: s.games, mode: Mode.game });
  //     }
  //   });
  // };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlot = () => {
    if (this.state.mode !== gameView.slot) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active">
              {this.state.slots.map((info: any, index: any) => (
                <a
                  href="javascript:;"
                  className="company-btn"
                  key={`key${info.nameEn}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if (info.used === "y") {
                      this.setState({ gameCode: info.code });
                      this.setState({ gameName: info.nameKo });
                      this.setState({ popupStatuses: popupView.game });
                      this.setState({ mode: gameView.slotlist });
                      this.handleSlotGames(info.code);
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <div className="main-container">
                    <img className="main-img" src={info.mobileImg} />
                    <div className="hover">
                      <p className="view-text">VIEW GAME</p>
                      <button className="view-btn">게임보기</button>
                    </div>
                  </div>
                  <div className="company-logo">
                    {/* <img src="img/slot-logo/aristocrat.png"> */}
                    {info.nameEn}
                  </div>
                  <div className="company-name">
                    <span>{info.nameKo}</span>
                  </div>
                </a>
              ))}
            </div>
          </section>
        </main>
      </>
    );
  };

  RenderSlotList = () => {
    if (this.state.mode !== gameView.slotlist) {
      return <></>;
    }
    return (
      <>
        <main className="page-content sub-page">
          <section className="gamelist-section">
            <div className="container">
              <div className="game-menu">
                {/* <a href="javascript:;" className="menu-link hot-link">
                  <div className="img-cont">
                    <img src="/img/icon/hot-tag.png" />
                  </div>
                  <div className="text-cont">
                    <span>HOT GAMES</span>
                  </div>
                </a> */}

                {this.state.slots.map((info: any, index: any) => (
                  <a
                    href="javascript:;"
                    className="menu-link"
                    key={`key${info.nameEn}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if (info.used === "y") {
                        this.setState({ gameCode: info.code });
                        this.setState({ gameName: info.nameKo });
                        this.setState({ popupStatuses: popupView.game });
                        this.setState({ mode: gameView.slotlist });
                        this.handleSlotGames(info.code);
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    <div className="img-cont">
                      {/* <img src="/img/slot-logo/aristocrat.png" /> */}
                      {info.nameEn}
                    </div>
                    <div className="text-cont">
                      <span>{info.nameKo}</span>
                    </div>
                  </a>
                ))}
              </div>
              <div className="game-list">
                {this.state.slotgames.map((info: any, index: any) => (
                  <a
                    href="javascript:;"
                    className="game-btn"
                    onClick={() => {
                      this.handleOpenSlot(info.code, info.gameCompany);
                    }}
                  >
                    <div className="main-cont">
                      <img className="main-img" src={`${info.imgUrl}`} />
                      <button className="play-btn">
                        <i className="fa fa-play" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="footer">
                      <span>{info.nameKo}</span>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </section>
        </main>
      </>
    );
  };

  RenderCasino = () => {
    if (this.state.mode !== gameView.casino) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active">
              {this.state.casionos.map((info: any, index: any) => (
                <a
                  href="javascript:;"
                  className="company-btn"
                  key={`key${info.nameEn}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if (info.used === "y") {
                      this.handleOpenSlot(info.code, info.gameCompany);
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  {/* <img className="hot-tag" src="/img/icon/hot-tag.png" /> */}
                  <div className="main-container">
                    <img className="main-img" src={info.mobileImg} />
                    <div className="hover">
                      <p className="view-text">VIEW GAME</p>
                      <button className="view-btn">게임보기</button>
                    </div>
                  </div>
                  <div className="company-logo">
                    {/* <img src="img/casino-logo/evolution.png"> */}
                    {info.nameEn}
                  </div>
                  <div className="company-name">
                    <span>{info.nameKo}</span>
                  </div>
                </a>
              ))}
            </div>
          </section>
        </main>
      </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== gameView.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices = this.state.notices;
    let widhdraws = this.state.widhdraws;

    if (this.state.mode === gameView.slotlist) {
      return <></>;
    }
    return (
      <>
        <section className="board-section">
          <div className="board-pane">
            <div className="header">
              <p>공지</p>
            </div>
            <table>
              {notices.map((info: any, index: any) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  <td>
                    <span className="text-yellow">[공지]</span>&nbsp;
                    {info.title}
                  </td>
                  <td className="date">{ConvertDate(info.regdate)}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="board-pane withdraw">
            <div className="header">
              <p>실시간 출금리스트</p>
            </div>
            <div className="rolling-realtime">
              <ul>
                {widhdraws.map((info: any, index: any) => (
                  <li className="tr">
                    <div className="user">
                      <span className="bullet"></span>
                      <span>{info.id}</span>
                    </div>
                    <div className="amount">
                      <span>{info.price}</span>
                    </div>
                    <div className="date">
                      <span>{info.date}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="board-pane service">
            <div className="header">
              <p>24시 고객서비스센터</p>
            </div>
            <div className="service-content">
              <a
                href="https://www.google.com/intl/ko_kr/chrome/"
                className="dl-link"
                target="_blank"
              >
                <img src="/img/icon/chrome.png" />
                <p className="mt-4 mb-0">크롬다운로드</p>
              </a>
              <a
                href="https://adobe-flash-player.kr.download.it/"
                className="dl-link"
                target="_blank"
              >
                <img src="/img/icon/flash.png" />
                <p className="mt-4 mb-0">플래시다운로드</p>
              </a>
              <div className="info text-left ml-md-auto">
                <p>
                  원활한 게임서비스를 위해서 크롬 및 플래시를 다운로드를
                  받으세요. 더 궁금한 사항은 고객센터로 문의바랍니다.
                </p>
                <button
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.help }); //고객센터
                  }}
                >
                  <img src="/img/icon/arrow.png" /> 고객센터문의하기
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }

      return <div></div>;
    };

    //

    return (
      <>
        <section className="banner-section">
          <div
            className="banner-carousel carousel"
            data-ride="carousel"
            data-pause="false"
            data-interval="4000"
          >
            <div className="carousel-inner"></div>
            <section className="menu-section">
              <div className="menu-container">
                <div className="menu-pane">
                  <button
                    className="slot-toggle active"
                    onClick={() => {
                      this.setState({ mode: gameView.slot }); //슬롯
                    }}
                  >
                    <img src="img/icon/slot.png" /> 슬롯게임
                  </button>
                  <button
                    className="casino-toggle"
                    onClick={() => {
                      this.setState({ mode: gameView.casino }); //카지노게임
                    }}
                  >
                    <img src="img/icon/casino.png" /> 카지노게임
                  </button>
                  <button
                    className="minigame-toggle"
                    onClick={() => {
                      this.setState({ mode: gameView.game }); //게임
                    }}
                  >
                    <img src="img/icon/minigame.png" /> 게임
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="background-images">
            <img className="cowboy" src="img/banner/cowboy.png" />
            <img className="gunman" src="img/banner/gunman.png" />
            <img className="glow-left" src="img/banner/glow-left.png" />
            <img className="glow-right" src="img/banner/glow-right.png" />
          </div>
        </section>
        {this.RenderSlot()}
        {this.RenderCasino()}
        {this.RenderGame()}
        {this.RenderSlotList()}
        {this.RenderNotice()}

        {RenderPopup()}
      </>
    );
  }
}
