import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)``;

const CustomTableCell2 = styled(TableCell)``;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className="ng-scope" key={row._id}>
        <CustomTableCell2 className="width12 text-center ng-binding">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
          className="text-left width70"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {row.isRead === "y" ? "확인" : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClosePopup: () => any;
  handleChange: (viewName: string) => any;
  handleClose: () => any;

  note: any;
}

interface State {
  helps: any;
  mode: string;
  regdate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: noteView.list,
      title: "",
      contents: "",
      regdate: "",
      isRead: false,
      text: "",
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  render() {
    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <table className="bs-table with-depth">
            <thead>
              <tr>
                <th></th>
                <th>제목</th>
                <th>작성일</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              {this.props.note.map((info: any, index: any) => (
                <>
                  <tr
                    className="depth-click"
                    onClick={() => {
                      service.do_read_note(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.contents,
                        regdate: info.regdate,
                        isRead: info.isRead,
                        text: info.text,
                        mode: noteView.view,
                      });
                    }}
                  >
                    <td className="count-td">{index + 1}</td>
                    <td className="title-td">
                      <div className="w-100">
                        <label className="cutted-text">{info.title}</label>
                      </div>
                    </td>
                    <td className="date-td">{ConvertDate(info.regDate)}</td>
                    <td className="nav-td">
                      {info.isRead === "y" ? "확인" : "미확인"}
                    </td>
                  </tr>
                  <tr className="dropdown">
                    <td colSpan={6}>
                      <div className="mess-cont">
                        <div className="inner">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: info.text,
                            }}
                          ></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          {/* 목록 */}
          {/* <table className="Modal_info_table__1L6Ml" style={{ color: "#fff" }}>
            <thead>
              <tr>
                <th className="w-10"></th>
                <th className="w-50 ">제목</th>
                <th className="Modal_tablet_mode_hide__2vwY-">작성일</th>
                <th className="Modal_tablet_mode_hide__2vwY-">상태</th>
              </tr>
            </thead>
            <tbody>
              {this.props.note.map((info: any, index: any) => (
                <tr
                  className="ng-scope"
                  onClick={() => {
                    service.do_read_note(info._id);
                    this.setState({
                      title: info.title,
                      contents: info.contents,
                      regdate: info.regdate,
                      isRead: info.isRead,
                      text: info.text,
                      mode: noteView.view,
                    });
                  }}
                >
                  <td>{index + 1}</td>
                  <td>
                    <div className="w-100">
                      <label className="cutted-text">{info.title}</label>
                    </div>
                  </td>
                  <td className="Modal_tablet_mode_hide__2vwY-">
                    {ConvertDate(info.regDate)}
                  </td>
                  <td className="Modal_tablet_mode_hide__2vwY-">
                    {info.isRead === "y" ? "확인" : "미확인"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
          {/* /목록 */}
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          {/* 상세 */}
          <div className="form-container">
            <div className="form-group">
              <div className="labels">
                <span className="dot"></span>
                <span>제목</span>
              </div>
              <div className="infos">
                <input type="text" readOnly value={this.state.title} />
              </div>
            </div>
            <div className="form-group no-label">
              <div className="infos">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.text }}
                ></div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-grp">
              <button
                onClick={() => {
                  this.setState({
                    mode: noteView.list,
                  });
                }}
              >
                <i className="fas fa-check-square" aria-hidden="true"></i> 목록
              </button>
            </div>
          </div>

          {/* <div className="textview-area">
            <div className="Modal_textview_area_header_2__3sQ9e">
              <label className="text-warning">
                <i className="fas fa-sticky-note mr-2"></i> 쪽지함 - Note
              </label>
            </div>
            <div className="Modal_textview_area_header__1QgCe">
              <label>{this.state.title}</label>
              <span className="float-right">
                {ConvertDate(this.state.regdate)}
              </span>
            </div>
            <div className="scroll-wrapper">
              <div className="Modal_textview__2UO1S px-0">
                <div className="Home_editor_wrapper__2Ta5Z Home_read_editor__3A1I6">
                  <div
                    style={{
                      position: "relative",
                      outline: "none",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center w-100 py-1">
              <button
                className="dark-web-btn"
                onClick={() => {
                  this.setState({
                    mode: noteView.list,
                  });
                }}
              >
                <i className="fas fa-th-list"></i> 목록
              </button>
            </div>
          </div> */}
          {/* /상세 */}
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>쪽지</h5>
                      <span>NOTE</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {RenderList()}
                    {RenderView()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
