import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  point: string;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: "0",
      point: "0",
    };
  }

  handleChangeToBalance = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>금고</h5>
                      <span>POINT</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button className="mm-btn active">금고</button>
                      <button
                        className="mm-btn"
                        onClick={() => {
                          this.props.handleChange(popupView.comp); //콤프
                        }}
                      >
                        콤프
                      </button>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.balance + this.props.user.in_balance)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.point)}
                          />
                        </div>
                      </div>
                      <div className="form-group w-btn">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고입금</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button onClick={this.handleChangeToBalance}>
                            <i className="fa fa-check-double"></i> 입금하기
                          </button>
                        </div>
                      </div>
                      <div className="form-group w-btn">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고출금</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="0"
                            value={this.state.point}
                            onFocus={() => this.setState({ point: "" })}
                            onChange={(e) =>
                              this.setState({
                                point: e.target.value,
                              })
                            }
                          />
                          <button onClick={this.handleChangeToPoint}>
                            <i className="fa fa-check-double"></i> 출금하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="Modal_modal_wrapper__bmYcZ">
              <div className="Modal_modal_overlay__Ag4FI animate__animated animate__fadeIn"></div>
              <div className="Modal_modal_inner_wrapper__2J_Td">
                <div className="Modal_app_modal__WUCt9 animate__animated animate__bounceIn">
                  <div className="Modal_modal_header__3CHHh">
                    <img src="/images/logo.png" />
                    <button
                      className="Modal_close_modal__3PQ7Q"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="Modal_modal_options__1UDxt">
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit); //입금신청
                      }}
                    >
                      입금신청
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw); //출금신청
                      }}
                    >
                      출금신청
                    </button>
                    <button className="Modal_active__2zgwL">금고</button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.comp); //콤프
                      }}
                    >
                      콤프
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.user); //마이페이지
                      }}
                    >
                      마이페이지
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.help); //고객센터
                      }}
                    >
                      고객센터
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.notice); //공지사항
                      }}
                    >
                      공지사항
                    </button>
                    <button
                      className="[object Object]"
                      onClick={() => {
                        this.props.handleChange(popupView.note); //쪽지
                      }}
                    >
                      쪽지
                    </button>
                  </div>
                  <div className="Mobile_modal_options__2LBvR"></div>
                  <table className="Modal_modal_table__2W6GA">
                    <tbody>
                      <tr>
                        <th style={{ color: "#fff" }}>보유머니</th>
                        <td style={{ whiteSpace: "nowrap", color: "#fff" }}>
                          {ConverMoeny(this.props.user.balance)}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>금고머니</th>
                        <td style={{ whiteSpace: "nowrap", color: "#fff" }}>
                          {ConverMoeny(this.props.user.point)}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>금고입금</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button
                            className="dark-web-btn"
                            onClick={this.handleChangeToBalance}
                          >
                            입금하기
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ color: "#fff" }}>금고출금</th>
                        <td>
                          <input
                            className="dark-form-control w-100"
                            placeholder="0"
                            value={this.state.point}
                            onFocus={() => this.setState({ point: "" })}
                            onChange={(e) =>
                              this.setState({
                                point: e.target.value,
                              })
                            }
                          />
                          <button
                            className="dark-web-btn"
                            onClick={this.handleChangeToPoint}
                          >
                            출금하기
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
